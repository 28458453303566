import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { getWindowRouteState } from '../../utils/browserUtils';
import { compoundHref } from '../../utils/hrefUtils';
import { urlWrapper } from '../../utils/urlWrapperUtils';
import env from '../../env';
import TargetSelect from '../Utils/TargetSelect';
import Icon from '../Icon';
import Image from '../Image';

import './styles.scss';

import appDe from '../../translations/de.json';
import appEn from '../../translations/en.json';
import messagesDe from './translations/de.json';
import messagesEn from './translations/en.json';

const messages = {
    de: Object.assign({}, appDe, messagesDe),
    en: Object.assign({}, appEn, messagesEn),
};

/**
 *
 * @visibleName UITeaserCard
 */
class UITeaserCard extends React.Component {
    static mapApiItemDataToProps(data) {
        const {
            frontPictures,
            highResolutionImages,
            meta, // TODO meta must return pure 'url' value for the requested locale
            name,
            shortDescription,
        } = data;

        const pictureOption = highResolutionImages && highResolutionImages.length > 0
            ? highResolutionImages : frontPictures;

        return {
            imgSrc: pictureOption && pictureOption.length > 0 ? pictureOption[0].url : env.IMG_NOT_FOUND_URL,
            imgAlt: name,
            imgTitle: name,

            headline: name,
            linkText: shortDescription,
            url: null,
            meta,
        };
    }

    render() {
        const { url, meta, imgSrc, imgAlt, imgTitle, headline, linkText, target } = this.props;

        const imgSrcOption = imgSrc || env.IMG_NOT_FOUND_URL;
        const { language } = getWindowRouteState();

        const className = 'ui-teaser-card';

        const urlFromApi = !url && meta ? meta[`url_${language}`] : '';
        const linkProps = {
            href: urlWrapper(
                url || compoundHref([
                    messages[language]['CMS.activity.basePath'],
                    urlFromApi,
                ])),
            target,
            rel: target === '_blank' ? 'noopener noreferrer' : '',
        };

        return (
            <IntlProvider messages={messages[language]} locale={language}>
                <a className={className} {...linkProps}>
                    <div className={`${className}__wrapper`}>
                        <div className={`${className}__content`}>
                            <div className={`${className}__title`}>{headline}</div>
                            <div className={`${className}__description`}>
                                <Icon icon="estm_eng_chevron_thick" />
                                {linkText || (
                                    <FormattedMessage
                                        id="UITeaserCard.linkText"
                                        defaultMessage="More"
                                    />
                                )}
                            </div>
                        </div>
                        <Image
                            imgSrc={imgSrc}
                            imgAlt={imgAlt}
                            imgTitle={imgTitle}
                            imgSrcSet={[
                                `${imgSrcOption} 320w`,
                                `${imgSrcOption} 500w`,
                                `${imgSrcOption} 640w`,
                                `${imgSrcOption} 768w`,
                                `${imgSrcOption} 900w`,
                                `${imgSrcOption} 1024w`,
                                `${imgSrcOption} 1366w`,
                                `${imgSrcOption} 1536w`,
                                `${imgSrcOption} 1600w`,
                                `${imgSrcOption} 1920w`,
                                `${imgSrcOption} 2048w`,
                                `${imgSrcOption} 3072w`,
                            ]}
                            imgSizes={
                                '(min-width: 1536px) 1122px,' +
                                '(min-width: 1140px) 63.3vw,' +
                                '(min-width: 900px) 61vw,' +
                                '(min-width: 600px) 62.4vw, 127vw'
                            }
                        />
                    </div>
                </a>
            </IntlProvider>
        );
    }
}

UITeaserCard.formFields = Object.assign({}, {
    imgSrc: <input />,
    imgAlt: <input />,
    imgTitle: <input />,
    headline: <input />,
    url: <input />,
    linkText: <input />,
    target: <TargetSelect />,
});

UITeaserCard.propTypes = {
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    imgTitle: PropTypes.string,
    headline: PropTypes.string,
    url: PropTypes.string,
    linkText: PropTypes.string,
    target: PropTypes.string,
    /* eslint-disable-next-line react/forbid-prop-types */
    meta: PropTypes.object,
};

UITeaserCard.defaultProps = {
    imgSrc: '',
    imgAlt: '',
    imgTitle: '',
    headline: '',
    url: '',
    linkText: '',
    target: '_self',
    meta: null,
};

export default UITeaserCard;
