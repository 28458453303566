import React from 'react';
import PropTypes from 'prop-types';

import ItemDataFetcher from '../../api/object-admin/ItemDataFetcher';
import SelectItemType from '../EditForm/Inputs/SelectItemType';
import TargetSelect from '../Utils/TargetSelect';

import UITeaserCard from '../UITeaserCard';

import './styles.scss';

/**
 * @visibleName 3 Teaser Cards
 */
class TeaserCardList extends React.Component {
    render() {
        const {
            itemType1, itemId1, url1, targetUrl1,
            itemType2, itemId2, url2, targetUrl2,
            itemType3, itemId3, url3, targetUrl3,

            linkText1, headline1, imgTitle1, imgAlt1, imgSrc1,
            linkText2, headline2, imgTitle2, imgAlt2, imgSrc2,
            linkText3, headline3, imgTitle3, imgAlt3, imgSrc3,

            language, season,
        } = this.props;
        const className = 'teaser-card-listing';
        return (
            <div className={className}>
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-col-sm-2">
                            {itemType1 ? (
                                <ItemDataFetcher
                                    itemType={itemType1}
                                    itemId={itemId1}
                                    language={language}
                                    season={season}
                                >
                                    <UITeaserCard url={url1} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UITeaserCard
                                    imgSrc={imgSrc1}
                                    imgAlt={imgAlt1}
                                    imgTitle={imgTitle1}
                                    headline={headline1}
                                    linkText={linkText1}
                                    url={url1}
                                    target={targetUrl1}
                                />
                            )}
                        </div>
                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-col-sm-2">
                            {itemType2 ? (
                                <ItemDataFetcher
                                    itemType={itemType2}
                                    itemId={itemId2}
                                    language={language}
                                    season={season}
                                >
                                    <UITeaserCard url={url2} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UITeaserCard
                                    imgSrc={imgSrc2}
                                    imgAlt={imgAlt2}
                                    imgTitle={imgTitle2}
                                    headline={headline2}
                                    linkText={linkText2}
                                    url={url2}
                                    target={targetUrl2}
                                />
                            )}
                        </div>
                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-offset-md-1 g-col-sm-2">
                            {itemType3 ? (
                                <ItemDataFetcher
                                    itemType={itemType3}
                                    itemId={itemId3}
                                    language={language}
                                    season={season}
                                >
                                    <UITeaserCard url={url3} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UITeaserCard
                                    imgSrc={imgSrc3}
                                    imgAlt={imgAlt3}
                                    imgTitle={imgTitle3}
                                    headline={headline3}
                                    linkText={linkText3}
                                    url={url3}
                                    target={targetUrl3}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TeaserCardList.formFields = Object.assign({}, {
    itemType1: <SelectItemType id={'itemType1'} options={['activity']} />,
    itemId1: <input />,
    url1: <input />,
    targetUrl1: <TargetSelect />,

    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,
    headline1: <input />,
    linkText1: <input />,
    //
    itemType2: <SelectItemType id={'itemType2'} options={['activity']} />,
    itemId2: <input />,
    url2: <input />,
    targetUrl2: <TargetSelect />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,
    headline2: <input />,
    linkText2: <input />,
    //
    itemType3: <SelectItemType id={'itemType3'} options={['activity']} />,
    itemId3: <input />,
    url3: <input />,
    targetUrl3: <TargetSelect />,

    imgSrc3: <input />,
    imgAlt3: <input />,
    imgTitle3: <input />,
    headline3: <input />,
    linkText3: <input />,
});

TeaserCardList.propTypes = {
    // item1
    itemType1: PropTypes.string,
    itemId1: PropTypes.string,
    url1: PropTypes.string,
    targetUrl1: PropTypes.string,
    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,
    headline1: PropTypes.string,
    linkText1: PropTypes.string,
    // item2
    itemType2: PropTypes.string,
    itemId2: PropTypes.string,
    url2: PropTypes.string,
    targetUrl2: PropTypes.string,
    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,
    headline2: PropTypes.string,
    linkText2: PropTypes.string,
    // item3
    itemType3: PropTypes.string,
    itemId3: PropTypes.string,
    url3: PropTypes.string,
    targetUrl3: PropTypes.string,
    imgSrc3: PropTypes.string,
    imgAlt3: PropTypes.string,
    imgTitle3: PropTypes.string,
    headline3: PropTypes.string,
    linkText3: PropTypes.string,
    // other
    language: PropTypes.string,
    season: PropTypes.string,
};

TeaserCardList.defaultProps = {
    itemType1: '', // activity
    itemId1: '',
    imgSrc1: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt1: 'Default alt text',
    imgTitle1: 'Default title text',
    headline1: 'Die ultimative Trecking-Packliste',
    linkText1: 'Die Top-Austrustungs-tips fur Ihre Herbstreise',
    url1: '#',
    targetUrl1: '_self',
    //
    itemType2: '',
    itemId2: '',
    imgSrc2: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt2: 'Default alt text',
    imgTitle2: 'Default title text',
    headline2: 'Die Lieblingtrails vonDanny MacAskill',
    linkText2: '10 spannende Panoramatrails',
    url2: '#',
    targetUrl2: '_self',
    //
    itemType3: '',
    itemId3: '',
    imgSrc3: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt3: 'Default alt text',
    imgTitle3: 'Default title text',
    headline3: 'SUP auf dem Eis',
    linkText3: 'Zwischen den Schollen treiben lassen',
    url3: '#',
    targetUrl3: '_self',
    // other
    language: 'de',
    season: 'winter',
};

export default TeaserCardList;
