import env from '../env';

function compoundHref(hrefSections) {
    return hrefSections
        .join('/')
        .concat('/')
        .replace(/\/+/gi, '/');
}

function getUrlWithShortTtlPath(url) {
    return url.replace('/object/', env.OA_IMAGES_SHORT_TTL_PATH);
}

/**
 * Find out is url starts with http(s)
 * @param url string
 * @returns {boolean}
 */
function isAbsoluteUrl(url) {
    return (/^(f|ht)tps?:\/\//i.test(url));
}

export {
    compoundHref,
    getUrlWithShortTtlPath,
    isAbsoluteUrl,
};
